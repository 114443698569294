.constellation {
    position: absolute;
}
.constellation .exp-principal {
    position: absolute;
    text-transform: uppercase;
    color: #0033A1;
    text-align: center;
    z-index: 777;
    font-size: 3vw;
    font-family: classico-urw, sans-serif;
    line-height: .8;
}
.experiencegb {
    background: url("../images/experiences/constellationbg.jpg") 50% 50% no-repeat;
    background-size: cover;
}
@media (max-width:600px) {
    .experiencegb {
        background: url("../images/experiences/constellationbg-mobile.jpg") 50% 50% no-repeat;
        background-size: cover;
    }
}
.constellation .bloc-constellation .texte-system {
    font-family: classico-urw, sans-serif;
    text-transform: uppercase;
    font-size: 1.3vw;
    position: absolute;
    animation: 75s linear 0s infinite rotateInverse;
    transform-origin: center center;
    z-index: 77777;
}
.constellation .bloc-constellation a {
    color: #0033A1;
    padding: 2em 0;
    position: relative;
    z-index: 77777;
    text-align: center;
    text-decoration: none;
}
.constellation .exp-principal span {
    font-size: 2vw;
}
.constellation .bloc-constellation {
    position: absolute;
    animation: 75s linear 0s infinite rotate;
    transform-origin: center center;
}
.constellation .bloc-constellation .constellation-principale {
    width: 30vw;
    margin-left: auto;
    margin-right: auto;
}
.constellation .bloc-constellation .constellation-principale .centre {
    animation: 20s linear 0s infinite rotate;
    transform-origin: center center;
}
.constellation .bloc-constellation .constellation-secondaire .tour1 {
    animation: 14s linear 0s infinite rotateInverse;
    transform-origin: center center;
    animation-fill-mode: forwards;
}
.constellation .bloc-constellation .constellation-secondaire.hovered .tour1 {
    animation: 7s linear 0s infinite rotate;
    transform-origin: center center;
}
.constellation .bloc-constellation .constellation-secondaire.hovered .tour2 {
    animation: 5s linear 0s infinite rotate;
    transform-origin: center center;
}
#pointille {
    animation: 10s linear 0s infinite rotateInverse;
    transform-origin: center center;
}
.constellation.preload {
    opacity: 0;
}
.constellation {
    opacity: 1;
    transition: all 700ms ease 1s;
}
.preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
  }
.constellation svg {
    opacity: .7 !important;
}
.constellation .bloc-constellation.stop {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
    transform-origin: center center;
}
.constellation .bloc-constellation.stop .texte-system {
    transform-origin: center center;
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
}
.constellation .bloc-constellation .constellation-principale .aiguille {
    animation: 16s linear 0s infinite rotate;
    transform-origin: center center;
}
.constellation .bloc-constellation .constellation-secondaire .aiguille {
    animation: 16s linear 0s infinite rotate;
    transform-origin: center center;
}
.constellation .bloc-constellation .constellation-secondaire {
    width: 20vw;
    position: absolute;
    animation: 8s linear 0s infinite rotate;
    transform-origin: center center;
}
.constellation .bloc-constellation .first-const {
    right: -7.1vw;
    bottom: -7.1vw;
    animation: 8s linear 0s infinite rotate;
}
.constellation .bloc-constellation .chef {
    right: 1.4vw;
    bottom: 1.1vw;
}
.constellation .bloc-constellation .world {
    left: 0.5vw;
    bottom: 1.1vw;
}
.constellation .bloc-constellation .artists {
    left: 0.5vw;
    top: 2vw;
}
.constellation .bloc-constellation .art {
    right: 1.7vw;
    top: 2vw;
}
.constellation .bloc-constellation .first-const div {
    animation: 8s linear 0s infinite rotateInverse;
    transform-origin: center center;
}
.constellation .bloc-constellation .first-const p {
    position: absolute;
}
.constellation .bloc-constellation .second-const {
    right: 17.1vw;
    bottom: -7.1vw;
    animation: 12s linear 0s infinite rotate;
}
.constellation .bloc-constellation .third-const {
    right: -7.1vw;
    bottom: 17.1vw;
    animation: 20s linear 0s infinite rotate;
}
.constellation .bloc-constellation .fourth-const {
    right: 17.1vw;
    bottom: 17.1vw;
    animation: 16s linear 0s infinite rotate;
}
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotateInverse {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}