@import url("https://use.typekit.net/ado2rxa.css");
* {
    outline: none !important;
}
::selection {
    background: #0033A1;
    color: #fff;
}
::-moz-selection {
    background: #0033A1;
    color: #fff;
}
::-webkit-scrollbar {
    background: #ddd;
    width: 7px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ccc;
}
::-webkit-scrollbar-thumb {
    background: #0033A1;
    border-radius: 2px
}
body, html {
    overflow-x: hidden;
    font-family: 'Oswald', sans-serif;
    font-weight: normal;
    line-height: 1.25;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
input[type="submit"] {
    cursor: pointer;
}
.g-recaptcha {
    visibility: hidden;
    height: 0;
}
.verify {
    transition: all 700ms ease;
    color: #fff;
    background-color: #0033A1;
    z-index: 77777777;
    position: fixed;
    padding: 15px;
    opacity: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.verify.off {
    opacity: 0;
    z-index: -7;
}
.verify img {
    max-width: 300px;
}
.verify p {
    max-width: 500px;
    margin: auto
}
.verify label {
    font-weight: bold;
    color: #fff;
}
.verify h1 {
    color: #fff;
    font-weight: normal;
    text-shadow: none;
}
.btn.btn-verify {
    background-color: #fff;
    border-color: #fff;
    color: #0033A1 !important;
    text-transform: uppercase;
    padding: .3em 2.5em;
    border-radius: 30px;
}
.btn-verify:hover, .btn-verify:active, .btn-verify:focus {
    border-color: #fff;
}
a {
    text-decoration: none;
}
header {
    position: absolute !important;
    top: 2vh;
    z-index: 599;
}
header nav {
    font-size: 1.2vw;
    color: white;
}
header .logo {
    margin-top: 5vh;
}
header .burger {
    text-align: right;
    cursor: pointer;
    position: fixed;
    right: 1vw;
    z-index: 250;
}
header .close-menu {
    text-align: right;
    cursor: pointer;
    position: fixed;
    right: 1vw;
}
nav {
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 6001;
    background-color: #0033A1;
    display: none;
    top: 0;
}
nav .under-nav {
    height: 100vh;
    width: 100%;
}
nav .networks {
    position: absolute;
    bottom: 15vh;
}
nav .networks div {
    margin-left: auto;
    margin-right: auto;
}
nav .networks a:nth-child(1) img {
    padding-right: 30px;
}
nav .networks img {
    height: 36px;
    width: auto;
}
nav ul li {
    font-size: 1vw;
    margin-right: -0.3vw;
    text-align: center;
    padding-bottom: 2vh;
}
nav ul li a {
    text-decoration: none;
    color: white;
}
nav ul li a:hover {
    color: #FFFFFF;
    text-decoration: none;
}
nav ul li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: white;
    transition: width .3s;
    margin: auto;
    padding: 0;
}
nav ul .little a {
    font-size: 0.9vw;
    font-family: 'Optima', Tahoma, Geneva, Verdana, sans-serif;
    font-family: classico-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
}
nav ul li a:hover::after {
    width: 2vw;
}
.px-vw {
    min-width: 110px;
    max-width: 225px;
    margin: auto;
    display: block;
}
.navigation a {
    color: white;
    text-decoration: none;
    font-size: 1vw;
}
.navigation a:hover {
    color: #FFFFFF;
    text-decoration: none;
}
.navigation a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: white;
    transition: width .3s;
    margin: auto;
    padding: 0;
}
.navigation a:hover::after {
    width: 2vw;
}
.header .logo {
    margin-top: 0;
}
.sup {
    z-index: 200;
}
.carousel-control-prev, .carousel-control-next {
    opacity: 1;
    width: 2vw;
}
.carousel-control-prev:hover, .carousel-control-next:hover {
    opacity: 1;
}
.experiences .logo {
    margin-left: unset !important;
}
.experience.experiences .logo {
    margin-left: auto !important;
}
.carousel-control-prev {
    right: 0;
    height: 2vw;
    left: auto;
    top: 4vw;
}
.carousel-control-next {
    height: 2vw;
    right: -2vw;
    top: 2vw;
}
.carousel-control-prev-icon {
    background-image: url('../images/previous.svg');
    width: 2vw;
    height: 2vw;
    margin-right: -1px;
}
.triman {
    width: 55px;
}
.carousel-control-next-icon {
    background-image: url('../images/next.svg');
    width: 2vw;
    height: 2vw;
    margin-left: -1px;
}
.home-block h1 {
    color: #0033A1;
    font-size: 2.2vw;
    margin-bottom: 3vh;
    font-weight: 200;
}
.home-block h2 {
    color: #0033A1;
    font-size: 2.2vw;
    margin-bottom: 3vh;
    font-weight: 200;
}
.home-block h3 {
    color: white;
    font-size: 1.6vw;
    margin-bottom: 3vh;
    font-weight: 200;
    text-transform: uppercase;
}
.home-block p {
    font-family: 'Optima';
    font-family: classico-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.9vw;
}
.home-block-1 {
    height: 100vh;
    width: auto;
    background: url('../images/home-fond.jpg') no-repeat center center;
    background-size: cover;
    position: relative;
}
.home-block-1 .castle {
    position: absolute;
    top: 0;
    vertical-align: top;
    z-index: 100;
}
.home-block-1 .cloud-left {
    position: absolute;
    top: 10vh;
    z-index: 90;
    left: 0;
}
.home-block-1 .cloud-right {
    position: absolute;
    top: 20vh;
    right: -15vw;
    z-index: 90;
    width: 50vw;
}
.home-block-2 {
    margin-top: 15vh;
    position: relative;
    font-weight: 200;
}
.carousel-mobile {
    display: none;
}
.home-block-2 .left {
    left: 0;
    z-index: 100;
}
.home-block-2 .right {
    position: absolute;
    right: 0;
    z-index: 90;
    bottom: -10vh;
}
.home-block-2 .right .first {
    padding-top: 8vh;
    padding-bottom: 8vh;
}
.home-block-2 .right .second {
    background-color: #0033A1;
    padding-top: 6vh;
    padding-bottom: 10vh;
}
.home-block-2 .right .second ul {
    text-align: right;
    font-size: 0.9vw;
}
.home-block-2 .right .second ul a::after {
    content: ' <';
    padding-left: 5px;
    visibility: hidden;
}
.home-block-2 .right .second ul a::after {
    visibility: visible;
}
.home-block-2 .right .second ul a {
    color: white;
    text-decoration: none;
    letter-spacing: 0.1vw;
}
.home-block-2 .right .second ul li {
    padding-bottom: 1vh;
}
.home-block-3 {
    margin-top: 10vh;
}
.home-block-3 .first {
    background: url('../images/home/block-3/grey-back.jpg');
    background-size: cover;
    padding-top: 20vh;
    padding-bottom: 10vh;
}
.home-block-3 .first .our-wines {
    position: absolute;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    writing-mode: lr-tb;
    color: #e1e1e1;
    font-family: 'Oswald';
    font-weight: 200;
    font-size: 1.5vw;
    top: 4vw;
    left: -4vw;
}
.home-block-3 .second a {
    text-transform: uppercase;
    letter-spacing: 0.1vw;
    background-color: #0033A1;
    color: white;
    padding: 0.2vw 2vw 0.2vw 2vw;
    font-weight: 400;
    font-size: 0.8vw;
    text-decoration: none;
}
.home-block-3 .third {
    background-color: #0033A1;
    padding: 12vh 0 10vh 0;
    top: -10vh;
    min-height: 28vw;
}
.home-block-3 .third p {
    color: white;
}
.home-block-3 .third .carousel .carousel-item {
    z-index: 200;
}
.home-block-3 .third .cuvee-visu {
    position: absolute;
    left: -13vw;
    z-index: 120;
    top: -50vh;
    width: 90%;
}
.home-block-3 .third .cuvee-visu .one-cuvee {
    display: none;
}
/* .home-block-3 .third .cuvee-visu img {
    width: 9vw;
} */
.home-block-3 .third .savoir-plus {
    text-transform: uppercase;
    font-family: 'Oswald';
    font-weight: 200;
}
.home-block-3 .third .savoir-plus a {
    text-decoration: none;
    color: white;
}
.home-block-3 .third .carousel-control-prev {
    right: -2.2vw;
    top: -1vw;
}
.home-block-3 .third .carousel-control-next {
    right: -4vw;
    top: -3vw;
}
.experiences .carousel-item.active .titrage, .experiences .carousel-item:hover .titrage {
    color: white !important;
    transition: color 1s ease;
}
.home-block-4 {
    background: url('../images/home/block-4/hom-img-2-cave2-retouche2.jpg') top center no-repeat;
    width: 100%;
    min-height: 70vh;
    margin-bottom: 90px;
    background-size: cover;
    color: white;
    padding-top: 7vw;
}
.home-block-4 p a {
    color: white;
    text-transform: uppercase;
    font-family: 'Oswald';
    font-weight: 200;
    text-decoration: none;
}
.home-block-5 {
    position: relative;
    z-index: 120;
}
.home-block-5 .first p {
    position: absolute;
    top: 10vh;
    left: -9vw;
    color: #0033A1;
    font-size: 2vw;
    font-weight: 700;
    text-align: right;
    line-height: 120%;
}
.de .home-block-5 .first p {
    left: -30vw;
    width: 30vw;
}
.home-block-5 .second {
    padding-top: 15vh;
}
.home-block-5 .savoir-plus {
    text-transform: uppercase;
    font-family: 'Oswald';
    font-weight: 200;
}
.home-block-5 .savoir-plus a {
    text-decoration: none;
    color: #0033A1;
}
.home-block-6 {
    background-color: #0033A1;
    color: white;
    padding-top: 6vh;
    padding-bottom: 35vh;
    margin-top: -18vh;
}
.home-block-6 p {
    font-family: 'Oswald';
    text-align: right;
    line-height: 200%;
    position: relative;
    z-index: 500;
}
.home-block-6 p a {
    color: white;
    text-decoration: none;
}
.home-block-6 p a::after {
    content: ' <';
    padding-left: 5px;
    visibility: hidden;
}
.home-block-6 p a::after {
    visibility: visible;
}
.home-block-7 {
    margin-top: -20vh;
    position: relative;
    z-index: 120;
}
.home-block-7 a {
    color: white;
    text-decoration: none;
}
.home-block-7 .left {
    padding-right: 4vw;
}
.home-block-7 .one {
    transition: filter .5s;
    padding: 0;
}
.home-block-7 .one:hover {
    filter: brightness(90%);
}
.home-block-7 .right {
    padding-left: 4vw;
    margin-top: 10vh;
}
.home-block-7 .right img {
    padding: 0;
}
.home-block-7 .one .groupe-title {
    position: absolute;
    bottom: 0;
    padding: .5em 1em .8em 1em;
    transition: box-shadow .5s;
}
.home-block-7 .one:hover .groupe-title {
    box-shadow: inset 0 10vh 0 0 rgba(0, 0, 0, .5);
}
.home-block-7 .groupe-title img {
    box-shadow: none;
    height: auto;
    width: 1.5vw;
}
.home-block-7 .groupe-title h3 {
    margin: 0;
    text-transform: none;
}
.home-block-7 .groupe-title h3 span {
    font-size: 1.3vw;
}
.home-block-8 {
    background-color: #e3e3e3;
    height: 30vh;
    margin-top: -25vh;
}
.experiences.experience .vertical-text.text-uppercase {
    margin-top: -130px !important;
    margin-left: -10px;
}
.vertical-text {
    /*color: #0033A1;*/
    /*writing-mode: lr-tb;*/
    /*-webkit-transform: rotate(90deg);*/
    /*-moz-transform: rotate(90deg);*/
    /*-o-transform: rotate(90deg);*/
    /*transform: rotate(90deg);*/
    /*font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;*/
    /*font-size: 2vw;*/
    /*font-weight: 200;*/
    /*position: absolute;*/
    /*width: 18vw;*/
    /*margin: 0;*/
    /*padding: 0;*/
    /*top: 25%;*/
    color: #0033A1;
    writing-mode: lr-tb;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2vw;
    font-weight: 200;
    position: absolute;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    transform-origin: left top;
    left: 70%;
    margin-top: 20px;
    margin-left: 20px;
}
.cuvee-block-1 .right {
    background: url('../images/home/block-3/grey-back.jpg');
    background-size: cover;
    height: 66vh;
}
.cuvee-block-3 {
    margin-bottom: 5vw;
}
.cuvee-block-3 .wine-text {
    height: 13vw;
    background-color: #0033A1;
    margin-left: 12vw;
    z-index: 90;
    transition: margin-left 1s ease-in-out;
}
.cuvee-block-3 .wine-text .text {
    position: absolute;
    bottom: 0;
    right: 0;
}
.cuvee-block-3 .wine-text .text h2 {
    color: white;
    font-size: 1.5vw;
    font-weight: 200;
    text-transform: uppercase;
    text-align: right;
    margin-bottom: 1vw;
}
.cuvee-block-3 .wine-text .text p {
    text-align: right;
}
.cuvee-block-3 .wine-text .text a {
    color: white;
    font-size: 0.9vw;
    font-weight: 200;
    text-transform: uppercase;
    text-decoration: none;
}
.cuvee-block-3 .wine-text .text .wine-prec-txt {
    display: none;
}
.cuvee-block-3 .wine-text .text .wine-prec-txt:nth-child(1) {
    display: block;
}
.cuvees {
    position: absolute;
    z-index: 120;
    margin-top: -3vw;
}
.cuvees .one-wine {
    /* text-align: center;
    vertical-align: bottom; */
    margin-bottom: 10vw;
}
.cuvees.inside .one-wine img {
    vertical-align: bottom;
    width: 100%;
}
.cuvees .one-wine img {
    vertical-align: bottom;
    width: calc(60% - 20px);
}
.special-sub-cat .wine-text {
    margin-left: 17vw;
    transform: translateX(-30%);
}
.cuvee h1 {
    color: #0033A1;
    text-transform: uppercase;
    font-weight: 200;
    margin-top: 17vw;
    font-size: 2vw;
}
.cuvee .cuvee-block-1 .vertical-text, .cuvee-block-1 .vertical-text {
    text-transform: uppercase;
    width: 21vw;
    z-index: 1500;
}
.cuvee .cuvee-block-1 .vertical-text a, .cuvee-block-1 .vertical-text a {
    color: #8e8e8e;
    text-decoration: none;
    font-size: 1.5vw;
    line-height: 0;
}
.cuvee .cuvee-block-1 .right {
    min-height: 60vh;
}
.cuvee .cuvee-block-1 .right .contenu {
    color: #7d7d7d;
    font-family: 'Optima', Tahoma, Geneva, Verdana, sans-serif;
    font-family: classico-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-top: 1vw;
    font-size: 0.9vw;
}
.cuvee .animated-block {
    background-color: #0033A1;
    padding: 12vh 0 7vh 0;
    margin-top: -12vh;
    margin-bottom: 16vh;
    min-height: 50vh;
}
.cuvee .animated-block > div > ul > li {
    margin: 1vh 1vw;
}
.cuvee .animated-block .svg {
    z-index: 500;
    position: relative;
}
.cuvee .animated-block > div.svg img {
    width: 8vh;
    height: 8vh;
    z-index: 500;
}
.cuvee .animated-block > div.svg img:hover {
    background-color: white;
}
.cuvee .animated-block li a.active img {
    background-color: white;
}
.cuvee .presentation {
    position: relative;
}
.cuvee .presentation img {
    position: absolute;
    top: -25vw;
    left: -4vw;
    max-width: 50%;
}
.cuvee .description {
    padding: 5vh 9vw;
    color: white;
    font-family: 'Optima', Tahoma, Geneva, Verdana, sans-serif;
    font-family: classico-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.9vw;
    z-index: 200;
    position: relative;
}
.cuvee .control-prev {
    height: 2vw;
    transform: rotate(180deg);
    z-index: 120;
    margin-right: 2vw;
}
.cuvee .control-next {
    height: 2vw;
    transform: rotate(180deg);
    z-index: 120;
}
.cuvee .carousel-control-prev-icon {
    background-image: url('../images/next.svg');
    width: 2vw;
    height: 2vw;
}
.cuvee .carousel-control-next-icon {
    background-image: url('../images/previous.svg');
    width: 2vw;
    height: 2vw;
}
.cuvee .control {
    position: absolute;
    right: 5vw;
    bottom: -2vw;
}
.cuvee .control a {
    display: block;
}
.house h2 {
    color: #0033A1;
    font-size: 2.2vw;
    margin-top: 5vh;
    margin-bottom: 3vh;
    font-weight: 200;
    font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
}
.house h3 {
    color: #fff;
    font-size: 2vw;
    margin-bottom: 3vh;
    font-weight: 200;
    font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
}
.house p {
    font-size: 0.9vw;
    font-family: 'Optima', Tahoma, Geneva, Verdana, sans-serif;
    font-family: classico-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
}
.house #clos-pompadour {
    margin-top: 0;
    text-align: left;
}
.house-block > div > .vertical-text {
    color: #0033A1;
    writing-mode: lr-tb;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    font-family: 'Oswald';
    font-size: 2vw;
    font-weight: 200;
    position: absolute;
    width: 16vw;
    margin: 0;
    padding: 0;
    top: 30%;
    z-index: 600;
}
.experience .house-block > div > .vertical-text {
    width: 21vw;
}
.experience .house-block > div > .vertical-text a {
    color: #0033A1;
    text-decoration: none;
}
.experience .experience-block-1 img {
    max-width: 100%;
    width: 100%;
}
.next-experience {
    margin-top: 2vw;
}
.next-experience a {
    color: white;
    background-color: #273d86;
    text-transform: uppercase;
    font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    letter-spacing: 0.1vw;
    padding: 0.2vw 4vw 0.2vw 4vw;
    margin-top: 50px;
    text-decoration: none;
    display: block;
    text-align: center;
}
h2.title-right {
    text-align: right;
}
.house-block > div {
    background-color: #fff;
    background-size: cover;
    height: 50vh;
}
.house-block-1 {
    margin-top: -30vh;
}
.house-block-2 {
    background-color: #0033A1;
}
.house-block-2 p {
    color: #fff;
    font-family: 'Optima', Tahoma, Geneva, Verdana, sans-serif;
    font-family: classico-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.9vw;
    padding-top: 7vh;
    padding-bottom: 10vh;
}
.house-block-3 {
    width: 100%;
    height: auto;
}
.house-block-4 {
    margin-top: -32vh;
    z-index: 1;
}
.house-block-4 img {
    width: 100%;
    height: 100%;
}
.house-block-5 {
    margin-bottom: 20vh;
    color: #7d7d7d;
    font-family: 'Optima', Tahoma, Geneva, Verdana, sans-serif;
    font-family: classico-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 0.9em;
}
.house-block-6 {
    background: url('../images/home/block-3/grey-back.jpg');
    background-size: cover;
    width: 100%;
    height: 28vh;
}
.house-block-7 {
    margin-top: -28vh;
    z-index: 1;
}
.house-block-7-bg {
    padding-top: 6vh;
    padding-bottom: 10vh;
    background-color: #0033A1;
    color: #fff;
}
.house-block-8 {
    width: 92vw;
    margin: 8vh 4vw 0 4vw;
    z-index: 1;
}
.house-block-9 {
    color: white;
    font-weight: 200;
    background-color: #0033A1;
    margin-top: -44vh;
    padding: 5vh 0 30vh 0;
    z-index: 2;
}
.house-block-9 div {
    z-index: 1;
}
@media (min-width: 1201px) {
    .last-block-margin {
        margin: -10vh 0 10vh 0;
    }
}
.house-block-10-img {
    z-index: 50;
}
.house-block-10 {
    background: url('../images/home/block-3/grey-back.jpg');
    background-size: cover;
    width: 100%;
    height: 30vh;
    margin-top: -28vh;
}
.house-carousel {
    z-index: 199;
}
.house-carousel .carousel-control-next {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    height: 2vw;
    left: -2vw;
    top: 4vw;
}
.house-carousel .carousel-control-prev {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    right: auto;
    height: 2vw;
    left: 0;
    top: 2vw;
}
.art-block-1 {
    margin-top: -45vh;
}
.art-block-2 {
    margin-top: -55vh;
}
.art-block-2 .title-right {
    margin-left: -4vw;
    text-align: left;
}
.art-block-2-1 {
    background-color: #0033A1;
    padding-bottom: 15vh;
    padding-top: 5vh;
    color: white;
}
.art-block-4 {
    margin-top: -8vh;
}
.art-block-4 img {
    width: auto;
    height: auto;
}
.art-block-5 {
    margin-top: -5vw;
}
.art-block-5 img {
    margin-top: 0;
}
.art-block-6 {
    margin-bottom: 0;
    margin-top: 15vh;
    z-index: 120;
    position: relative;
}
.art-block-6 .padding-style {
    padding-bottom: 4vw;
}
.art-block-7 {
    margin-top: -8vw;
    z-index: 120;
    position: relative;
}
.art-block-8 {
    background-color: #0033A1;
    height: 30vh;
    margin-top: -5vw;
    z-index: 90;
}
.art-block-9 {
    background: url('../images/art/art-cave.jpg') center center;
    background-size: cover;
    color: white;
    z-index: 120;
    position: relative;
}
.art-block-9 h2 {
    color: white;
}
.art-block-9-1 {
    padding: 5vw 3vw 5vw 3vw;
}
.art-block-10 {
    margin-top: -5vh;
    z-index: 90;
}
.art-block-10 img {
    height: 50vh;
}
.art-block-11 {
    margin-top: -30vh;
}
.art-block-11 .title-right {
    margin-left: -4vw;
    text-align: left;
}
.art-block-12 {
    margin-top: 45vh;
}
.art-block-12 img {
    margin-top: 40vh;
}
.art-block-12-1 {
    background-color: #0033A1;
    color: white;
    padding: 2vw 0 7vw 0;
}
.art-block-12-2 {
    z-index: 120;
}
.experiences {
    margin-top: 10rem;
}
/*.experience {*/
/*    margin-top: 13rem;*/
/*}*/
.experience-block-1 .ombree {
    max-height: 418px;
}
.experiences .listing img {
    width: 100%;
}



.experiences .listing .titrage {
    position: absolute;
    bottom: 2vw;
    left: 2vw;
    color: #0033A1;
    text-transform: uppercase;
    z-index: 130;
}
.experiences .listing .titrage h2 {
    font-size: 1.6vw;
    margin: 0;
}
.experiences .listing .titrage .little {
    font-size: 0.9vw;
    color: white;
}
.experiences .carousel-control-prev {
    left: -1.9vw;
    height: 2vw;
    bottom: 8vw;
    top: auto;
    transform: rotate(180deg);
    z-index: 120;
}
.experiences .carousel-control-next {
    height: 2vw;
    left: -1px;
    top: auto;
    bottom: 10vw;
    transform: rotate(180deg);
    z-index: 120;
}
.owl-item {
    position: relative;
}
.owl-item .titrage {
    position: absolute;
    padding: .5em;
    color: #fff;
    bottom: 0;
}
.owl-nav {
    position: absolute;
    bottom: 40%;
    left: -2vw;
    width: 80px;
}
.experiences .carousel-control-prev-icon,
.owl-prev {
    border: none!important;
    background: url('../images/next.svg') 50% 50% no-repeat !important;
    width: 2vw;
    height: 2vw;
}
.owl-theme .owl-nav {
    margin-top: 0;
}
.experience-block-3 p {
    font-weight: 400;
}
.experience-block-3 strong {
    font-weight: 700;
    font-size: 1.1em;
}
.experiences .carousel-control-next-icon,
.owl-next {
    border: none!important;
    background: url('../images/previous.svg')  50% 50% no-repeat  !important;
    width: 2vw;
    height: 2vw;
}
.owl-next {
    top: -2vw;
    left: 2vw;
    position: absolute;
}
.owl-prev {
    /*left: -2vw;*/
    position: absolute;
}
.owl-next, .owl-prev {
    transform: rotate(180deg);
}
.owl-next span, .owl-prev span {
    display: none;
}
.experiences .voile {
    background-color: #e7e7e7;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 120;
    transition: 0.5s;
}
.owl-stage > .active ~ .active .voile {
    opacity: 0.9;
}
.owl-stage > .active .voile {
    opacity: 0;
}
.owl-stage div.titrage h2 {
    color: #ebebeb;
}
.owl-item.active:hover .voile {
    opacity: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
    .experiences .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -33.3333%;
        z-index: -1;
        display: block;
        visibility: visible;
    }
}
@media (min-width: 576px) and (max-width: 768px) {
    .experiences .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -50%;
        z-index: -1;
        display: block;
        visibility: visible;
    }
}
@media (min-width: 576px) {
    .experiences .carousel-item {
        margin-right: 0;
    }
    .experiences .carousel-inner .active + .carousel-item {
        display: block;
    }
    .experiences .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .experiences .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
        transition: none;
    }
    .experiences .carousel-inner .carousel-item-next {
        position: relative;
        transform: translate3d(0, 0, 0);
    }
    .experiences .active.carousel-item-left + .carousel-item-next.carousel-item-left,
    .experiences .carousel-item-next.carousel-item-left + .carousel-item,
    .experiences .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    .experiences .carousel-inner .carousel-item-prev.carousel-item-right {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        visibility: visible;
    }
    .experiences .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    .experiences .carousel-item-prev.carousel-item-right + .carousel-item,
    .experiences .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
        visibility: visible;
    }
}
@media (min-width: 768px) {
    .experiences .carousel-inner .active + .carousel-item + .carousel-item {
        display: block;
    }
    .experiences .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
        transition: none;
    }
    .experiences .carousel-inner .carousel-item-next {
        position: relative;
        transform: translate3d(0, 0, 0);
    }
    .experiences .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    .experiences .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
        visibility: visible;
    }
}
@media (min-width: 991px) {
    .experiences .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
        display: block;
    }
    .experiences .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
        transition: none;
    }
    .experiences .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -25%;
        z-index: -1;
        display: block;
        visibility: visible;
    }
    .experiences .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    .experiences .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: relative;
        transform: translate3d(100%, 0, 0);
        visibility: visible;
        display: block;
    }
}
.experience {
    margin-bottom: 12vh;
}
.experience h1 {
    color: #0033A1;
    font-weight: 200;
    font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3.2vw;
}
.titrage h2 {
    color: #848484;
    font-size: 1.2vw;
    margin-bottom: 0;
    font-weight: 200;
    font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
}
.experience h3 {
    color: #fff;
    font-size: 2vw;
    margin-bottom: 3vh;
    font-weight: 200;
    font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
}
.experience p:not(.vertical-text), .carousel-desk p:not(.vertical-text) {
    font-size: 1em;
    font-family: 'Optima', Tahoma, Geneva, Verdana, sans-serif;
    font-family: classico-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
}
.experience p.author {
    color: #0033A1;
    margin-bottom: 0;
    font-weight: bold;
}
.nav-owl-on .owl-nav {
    display: block !important;
}
.experience p.artist {
    font-weight: bold;
    margin: 0;
    font-size: 0.9vw;
}
a.experience-discover-button {
    font-size: 0.7vw;
    display: block;
    margin-top: 14vh;
    text-decoration: none;
    color: white;
    background-color: #0033A1;
    padding: 0.5vh 3.5vw 0.3vh 3.5vw;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.2vh;
    width: 19vw;
}
a.experience-discover-button:hover {
    background-color: #3366ff;
}
.experience-block-1 {
    margin-top: 5rem;
}
.owl-theme .owl-nav [class*=owl-] {
    margin: 0;
}
.experience-block-2 {
    margin-top: 50px;
}
.experience-block-3 {
    z-index: 0;
    background-color: #0033A1;
    padding: 40vh 10vh 50vh 10vh;
    margin-top: -32vh;
    color: white;
}
.experience-block-4 {
    position: absolute;
    background: url('../images/home/block-3/grey-back.jpg');
    background-size: cover;
    width: 100%;
    height: 55vh;
    margin-top: -55vh;
}
.experience-block-5 {
    margin-top: -20vh;
}
.experience-carousel {
    z-index: 199;
    margin-top: 5vw;
}
.experience-carousel .owl-stage{display: flex;}
.experience-carousel .owl-stage .item{height: 100%;}
.experience-carousel .owl-stage .item img{height: 100%;object-fit: cover;}
.experience-carousel .carousel-control-next {
    right: -1.98vw;
    height: 2vw;
    top: auto;
    bottom: 5vw;
}
.experience-carousel .carousel-control-prev {
    height: 2vw;
    right: -1px;
    bottom: 3vw;
    top: auto;
}

/* .cat-img{ position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 60%;}
.experience h1{ color: #0033A1;font-weight: 200;font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;font-size: 40px;}
.experience h2{ color: #0033A1;font-weight: 200;font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;font-size: 40px;}
.experience h3 {color: #212529;font-size: 30px;margin-bottom: 3vh;font-weight: 500;font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;}
.experience .bleu{background: #0033A1}
.experience .first{position: relative;z-index: 50;}
.experience .first .titre{text-align: right;}
.experience .first .titre h1{text-align: left;display: inline-block;}
.experience .first .content{position: relative;min-height: 250px;margin-top: 100px;}
.experience .first .content .box{ color: white;position: absolute;width: calc(100% + 100px);padding: 30px;padding-right: 100px;padding-bottom: 60px;}
.experience .first .img{position: relative;}
.experience .first .img img{max-height: 500px;}
.experience .left{margin-top: 100px;position: relative;z-index: 50;}
.experience .left .titre{}
.experience .left .content{margin-left: 50px;margin-top: 20px;}
.experience .left .img{position: relative;float: right;}
.experience .left .img img{max-height: 500px;}

.experience .right{margin-top: 100px;position: relative;z-index: 50;}
.experience .right .titre{}
.experience .right .content{margin-top: 20px;}
.experience .right .img{position: relative;}

.experience .center{position: absolute;top: 50%;transform: translateY(-50%);}
.experience .pr{position: relative;width: 80%;height: 100px;}
.experience .gris{width: 50%;height: 250px;background-color: #e6e6e6;position: absolute;left: 0;z-index: 10;} */


/*.exp-img-height {*/
/*    height: 400px;*/
/*}*/
@keyframes visibilityy {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@media (max-width: 991.98px) {
    .experiences .logo {
        margin-left: auto !important;
    }
}
@media (max-width: 1199.98px) {
    .owl-next, .owl-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        width: 32px;
        height: 32px;
    }
    .owl-nav {
        position: static;
    }
    .owl-next {
        right: 1em;
        left: unset;
    }
    .owl-prev {
        left: 1em;
    }
    .cat-img{    position: initial;width: auto;transform: initial;margin: 50px auto;}
    .experience .first .titre h1 {display: block;text-align: center;}
    .experience .first .content{min-height: inherit;max-width: inherit;margin-bottom: 20px;margin-top: 0;}
    .experience .first .content .box {color: white;position: inherit;width: auto;padding: 30px;padding-right: 30px;padding-bottom: 30px;}
    .experience .first .img {position: relative;margin: auto;display: inline-block;text-align: center;left: 50%;transform: translateX(-50%);margin-bottom: 50px;}
    .experience .pr{display: none;}
    .experience .left{margin-top: 20px;}
    .experience .right{margin-top: 20px;}
    .experience .left .titre {text-align: center;}
    .experience .center {position: relative;top: auto;transform: inherit;}
    .experience .left .content {margin-left: 0;margin-top: 20px;}
    .experience .left .img {float: none;position: relative;margin: auto;display: inline-block;text-align: center;left: 50%;transform: translateX(-50%);margin-bottom: 50px;}
    .experience .right .img {float: none;position: relative;margin: auto;display: inline-block;text-align: center;left: 50%;transform: translateX(-50%);margin-bottom: 50px;}
    .experience .right .titre {text-align: center;}
}
.absolute-centered-a a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: #0033A1;
    padding: 2em 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}
.contact {
    margin-bottom: 16vh;
    margin-top: 24vh;
}
.contact ul {
    margin: -1vh 0 4vh 0;
    list-style: inside;
}
.contact ul li {
    margin-right: -1.8vh;
    font-size: 0.9vw;
    font-family: classico-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
}
.contact ul li span {
    margin-left: -0.6vw;
}
.contact h1 {
    color: #0033A1;
    font-weight: 200;
    font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3.2vw;
    margin-top: 12vh;
}
.contact p.map {
    color: #0033A1;
    font-weight: 200;
    font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1vw;
    text-align: center;
    margin-bottom: 0;
}
.contact p {
    font-size: 0.9vw;
    font-family: classico-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
}
.contact p.bold {
    font-weight: bold;
}
.contact .adresse p {
    font-weight: bold;
    margin-bottom: 0;
}
.contact .adresse p:nth-child(2) {
    color: #0033A1;
}
.contact .visit-block {
    background-color: #0033A1;
    color: white;
    padding: 12vh 4vw;
    margin-top: -24vh;
    margin-bottom: 12vh;
}
.contact .reception-block {
    background-color: #0033A1;
    color: white;
    padding: 8vh 4vw;
    margin-top: -24vh;
}
.contact #map {
    height: 40vh;
    margin-bottom: 8vh;
}
.contact input[type=text], input[type=tel], input[type=email], select {
    width: 100% !important;
    padding: 1.2vh 0.8vw !important;
    margin: 0.8vh 0 !important;
    box-sizing: border-box !important;
    border: 1px solid #0033A1 !important;
    border-radius: unset !important;
    color: black !important;
}
.contact select {
    padding: 0 !important;
    height: 50px;
    padding-left: 0.5em !important;
}
.contact input[type=text]::placeholder, input[type=tel]::placeholder, input[type=email]::placeholder, select::placeholder {
    color: #b4b4b4 !important;
}
.contact textarea {
    width: 100%;
    padding: 1.2vh 0.8vw;
    margin: 0.7vh 0;
    border: 1px solid #0033A1;
    resize: none;
    height: calc(75% - 1.5vh);
    box-sizing: border-box;
}
.contact input[type=submit] {
    width: 100%;
    font-size: 0.9vw;
    text-decoration: none;
    color: white;
    background-color: #0033A1;
    padding: 1.3vh 0;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.2vh;
    border: none;
    height: calc(25% - 1.5vh);
}
.contact input[type=submit]:hover {
    background-color: #3366ff
}
.contact ::placeholder {
    color: black;
}
.legal-notices {
    margin-bottom: 8vh;
    margin-top: 24vh;
}
.legal-notices h1 {
    color: #0033A1;
    margin-bottom: 4vh;
    font-weight: 200;
    font-size: 2.2vw;
    font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
}
.legal-notices p {
    color: #272525;
    margin-bottom: 4vh;
    font-size: 0.9vw;
    font-family: classico-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
}
.legal-notices h4 {
    color: #0033A1;
    margin-bottom: 1.5vh;
    font-size: 0.9vw;
    font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
}
/* .legal-notices p:nth-of-type(2n +1) {
    color: #0033A1;
    margin-bottom: 1.5vh;
    font-size: 0.9vw;
    font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
}
.legal-notices p:nth-of-type(2n) {
    color: #272525;
    margin-bottom: 4vh;
    font-size: 0.9vw;
    font-family: classico-urw, sans-serif;
    font-weight: 400;
    font-style: normal;
} */
.abus {
    background-color: #f4f4f4;
    color: black;
    text-align: center;
    padding: 0.5vw 0 0.5vw 0;
    font-family: 'Oswald';
    font-weight: 200;
}
.abus p {
    font-size: 0.7vw;
    letter-spacing: 0.1vw;
    margin: 0;
}
footer {
    padding: 1.5vw 0 1.5vw 0;
    background-color: #0033A1;
}
footer .link li {
    padding: 1vw 1vw 0 1vw;
    color: white;
    font-size: 0.9vw;
}
footer .social img {
    width: 2vw;
}
footer a:nth-child(1) {
    margin-right: 1vw;
}
footer a {
    color: white;
    text-decoration: none;
}
footer a:hover {
    color: white;
    text-decoration: none;
}
.left-apparition {
    left: -50vw;
    position: relative;
    opacity: 0;
    transition: all 1s ease-out;
}
.right-apparition {
    right: -50vw;
    position: relative;
    opacity: 0;
    transition: all 1s ease-out;
}
.top-apparition {
    top: -50vw;
    position: relative;
    opacity: 0;
    transition: all 1s ease-out;
}
.bottom-apparition {
    bottom: -50vw;
    position: relative;
    opacity: 0;
    transition: all 1s ease-out;
}
.left-apparition.on {
    left: 0;
    opacity: 1;
}
.right-apparition.on {
    right: 0;
    opacity: 1;
}
.top-apparition.on {
    top: 0;
    opacity: 1;
}
.bottom-apparition.on {
    bottom: 0;
    opacity: 1;
}
.transition-0-5s {
    transition-duration: .5s;
}
.transition-0-75s {
    transition-duration: .75s;
}
.transition-1s {
    transition-duration: 1s;
}
.transition-1-25s {
    transition-duration: 1.25s;
}
.transition-1-5s {
    transition-duration: 1.5s;
}
.transition-2s {
    transition-duration: 2s;
}
.size-burger-cross {
    width: 3vw;
    max-width: 60px;
}
.photo-brightness img {
    filter: brightness(50%);
    -webkit-filter: brightness(50%);
}
.photo-brightness img:hover {
    filter: brightness(100%);
    -webkit-filter: brightness(100%);
}
.col-lg-1.wine-text.row.reduce-height {
    height: 13vw;
}
.photo-credit {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: black;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    opacity: 0.5;
}
.overlay {
    display: none;
    position: fixed;
    top: 0;
    background-color: #0033A1;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    z-index: 6002 !important;
}
.iconContainer {
    width: 300px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -100px;
}
.iconContainer p {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 20px;
    opacity: 0;
}
.phone {
    position: relative;
    left: 50%;
    margin-left: -80px;
    width: 0;
    height: 0;
    border: 0 solid #000;
    background: #a9a9a9;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 45px;
}
.phone i {
    text-align: center;
    width: 100%;
    line-height: 120px;
    font-size: 50px;
    color: #454545;
    opacity: 0;
}
nav ul .language a:hover {
    color: #FFFFFF;
    text-decoration: none;
    border: none;
}
nav ul .language a::after {
    content: '';
    display: block;
    width: auto;
    height: 0;
    background: white;
    transition: none;
    margin: auto;
    padding: 0;
}
nav ul .language a:hover::after {
    width: auto;
}
nav ul .language a {
    display: inline-block;
}
nav ul .language a:nth-child(1) {
    margin-right: 0.2vw;
}
nav ul .language a:nth-child(2) {
    margin-left: 0.2vw;
}
@media (max-width: 1200px) {
    .header {
        margin-top: auto;
    }
    nav ul li {
        padding-bottom: 1vh;
        font-size: 3vw;
    }
    nav ul .little a {
        font-size: 2vw;
    }
    .header .logo {
        margin-top: 1.5rem;
        padding-left: 30px;
        padding-right: 30px;
    }
    .size-burger-cross {
        width: 10vw;
    }
    .home-block h1, .home-block h2, .home-block h3 {
        font-size: 4vw;
        text-align: center;
    }
    .home-block p, .home-block-2 .right .second ul {
        font-size: 2.5vw;
    }
    .home-block-1 {
        height: 100vh;
    }
    .castle {
        position: relative;
        transform: scale(1) !important;
    }
    .home-block-2 {
        margin-top: 0;
    }
    .carousel-desk {
        display: none !important;
    }
    .carousel-mobile {
        display: block;
    }
    .home-block-2 .right {
        position: relative;
        bottom: 0;
    }
    .home-block-2 .right .first {
        padding-top: 6vh;
        padding-bottom: 6vh;
        text-align: center;
    }
    .home-block-2 .right .second {
        padding-bottom: 6vh;
        text-align: center;
    }
    .home-block-2 .right .second ul {
        text-align: center;
    }
    .home-block-3 {
        margin-top: 0;
    }
    .home-block-3 .first {
        padding-top: 6vh;
        padding-bottom: 6vh;
        text-align: center;
    }
    .home-block-3 .third {
        padding: 6vh 0 0 0;
        top: 0;
        text-align: center;
        background-color: #EEEEEE;
    }
    .home-block-3 .second div:nth-child(1) {
        background-color: #EEEEEE;
    }
    .home-block-3 .second p {
        margin-bottom: 0;
    }
    .home-block-3 .second a {
        font-size: 2vw;
        padding: 2vw;
    }
    .home-block-3 .second p {
        text-align: center
    }
    .home-block-3 .third .cuvee-visu {
        position: relative;
        top: 0;
        left: 0;
        text-align: center;
        margin-top: 0;
    }
    .home-block-3 .third .savoir-plus {
        margin-top: 3vh;
    }
    .home-block-3 .third .cuvee-visu img {
        width: 15vw;
    }
    .home-block-3 .third .carousel .carousel-item {
        min-height: 75vw;
    }
    .home-block-2 .carousel-control-prev {
        left: 0;
        top: calc(50% - 5vw);
        z-index: 200;
    }
    .home-block-2 .carousel-control-next {
        right: 0;
        top: calc(50% - 5vw);
        z-index: 200;
    }
    .home-block-3 .third .carousel-control-prev {
        left: 0;
        top: 0;
        z-index: 200;
    }
    .home-block-3 .third .carousel-control-next {
        right: 0;
        top: 0;
        z-index: 200;
    }
    .home-block-2 .carousel-control-next .carousel-control-next-icon {
        background-image: url('../images/next-white.svg');
    }
    .home-block-2 .carousel-control-prev .carousel-control-prev-icon {
        background-image: url('../images/previous.svg');
    }
    .home-block-3 .third .carousel-control-next .carousel-control-next-icon {
        background-image: url('../images/next-grey.svg');
    }
    .home-block-3 .third .carousel-control-prev .carousel-control-prev-icon {
        background-image: url('../images/previous-grey.svg');
    }
    .carousel-control-prev, .carousel-control-next, .carousel-control-prev-icon, .carousel-control-next-icon {
        width: 6vw;
        height: 6vw;
    }
    #carousel-block-3 .carousel-inner {
        background-color: #0033A1;
        padding: 28vh 0 0 0;
        margin-top: -20vh;
    }
    .home-block-4 {
        margin-bottom: 4vh;
        background: none;
        position: relative;
        padding-top: 0;
        min-height: 0;
    }
    .home-block-4 .text-exp {
        display: none;
    }
    .home-block-4 .contenu {
        position: absolute;
        top: 7vw;
    }
    .home-block-4 .contenu h3 {
        margin-bottom: 1vh;
    }
    .home-block-4 h3 {
        text-align: left !important;
    }
    .home-block-5 {
        padding-bottom: 6vh;
    }
    .home-block-5 .first p {
        font-size: 5vw;
        left: -28vw;
    }
    .de .home-block-5 .first p {
        font-size: 1.2em;
    }
    .home-block-5 .second {
        padding-top: 6vh;
        text-align: center;
    }
    .home-block-5 .second .savoir-plus {
        margin-top: 3vh;
        margin-bottom: 6vh;
    }
    .home-block-5 .second {
        padding: 0;
    }
    .home-block-6 {
        margin-top: 0;
        padding-top: 6vh;
        padding-bottom: 6vh;
    }
    .home-block-7 {
        margin-top: 0;
    }
    .home-block-7 .right {
        margin-top: 0;
    }
    .home-block-7 a {
        text-align: left;
    }
    .home-block-7 .groupe-title h3 {
        text-align: left;
    }
    .home-block-7 .groupe-title h3 span {
        font-size: 4.3vw;
    }
    .home-block-7 .left {
        padding: 0;
    }
    .home-block-7 .right {
        padding: 0;
    }
    .home-block-8 {
        display: none;
    }
    .abus p {
        font-size: 3vw;
    }
    .house h1, .house h2, .house h3 {
        font-size: 5vw;
        text-align: center;
        margin-bottom: 5vh;
    }
    .house p {
        font-size: 2.5vw;
        text-align: center;
    }
    .house #qualite {
        background-color: #0033A1;
        color: white;
        margin: 0;
        padding-top: 5vh;
    }
    .house-block-1 {
        margin-top: -40vh;
    }
    .house-block-2 p {
        padding-top: 4vh;
        padding-bottom: 4vh;
    }
    .house-block-4 {
        margin-top: 0;
    }
    .house-block-5 {
        margin-bottom: 5vh;
    }
    .house-block-6 {
        display: none;
    }
    .house-block-7 {
        margin-top: 0;
    }
    .house-block-7 #chantier-du-siecle {
        background-color: #0033A1;
        color: white;
        margin: 0;
        padding-top: 5vh;
    }
    .house-block-7-bg {
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
    .house-block-7-bg p {
        margin-bottom: 0;
    }
    .house-block-8 {
        margin: 0;
        width: 100%;
        height: auto;
    }
    .house-block-9 {
        margin-top: 0;
        padding-bottom: 5vh;
    }
    .house-block-9 p {
        margin-bottom: 0;
    }
    .house-block-10 {
        display: none;
    }
    .house #clos-pompadour {
        text-align: center;
        padding-top: 5vh;
    }
    .padding-0 {
        padding: 0 !important;
    }
    .art .art-block-1 {
        margin-top: -40vh;
    }
    .art .art-block-2 {
        margin-top: 0;
    }
    .art .art-block-2 h2 {
        margin-left: auto;
        text-align: center;
        background-color: #0033A1;
        color: white;
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 5vh;
    }
    .art .art-block-2-1 {
        padding-bottom: 5vh;
    }
    .art .art-block-5 {
        margin-top: 0;
    }
    .art .art-block-5 img {
        width: 100%;
    }
    .art .art-block-6 {
        margin-top: 5vh;
    }
    .art .art-block-4 {
        display: none;
    }
    .art-block-7 {
        margin-top: 5vh;
    }
    .art-block-8 {
        display: none;
    }
    .art-block-9 {
        background: none;
        margin-top: 5vh;
    }
    .art-block-9 h2 {
        color: #0033A1;
    }
    .art-block-9 p {
        color: #212529;
    }
    .art-block-10 {
        display: none;
    }
    .art-block-11 {
        margin-top: 0;
    }
    .art-block-11 .title-right {
        margin-left: auto;
        text-align: center;
        color: white;
        margin-bottom: 0;
        background-color: #0033A1;
        padding-top: 5vh;
    }
    .art-block-12 {
        margin-top: 0;
    }
    .experiences .listing .titrage h2 {
        font-size: 3.6vw;
    }
    .experiences .listing .titrage .little {
        font-size: 2vw;
    }
    .experiences .carousel-control-prev {
        left: 0;
        right: auto;
        top: calc(50% - 5vw);
        z-index: 200;
        transform: rotate(0);
    }
    .experiences .carousel-control-next {
        right: 0;
        left: auto;
        top: calc(50% - 5vw);
        z-index: 200;
        transform: rotate(0);
    }
    .experiences .carousel-control-prev, .experiences .carousel-control-next, .experiences .carousel-control-prev-icon, .experiences .carousel-control-next-icon {
        width: 10vw;
        height: 10vw;
    }
    .experiences .carousel-control-next .carousel-control-next-icon {
        background-image: url('../images/next-white.svg');
    }
    .experiences .carousel-control-prev .carousel-control-prev-icon {
        background-image: url('../images/previous.svg');
    }
    .experiences .listing .titrage {
        left: 4vw;
        bottom: 4vw;
    }
    .experience {
        margin-bottom: 0;
    }
    .experience h2, .experience h1 {
        font-size: 7vw;
    }
    .experience h1 {
        margin-bottom: 2vh;
    }
    .experience p, .experience p.artist {
        font-size: 4vw;
    }
    .experience .left-side {
        visibility: hidden;
    }
    .experience .experience-block-1 img {
        width: 200vw;
        left: -50vw;
        position: relative;
        text-align: center;
        max-width: initial;
    }
    .experience .experience-block-2 {
        text-align: center;
        font-size: 4vw;
        margin-top: 50px;
    }
    .experience .experience-block-3 {
        margin-top: 0;
        padding: 0;
        text-align: center;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }
    .experience-block-4 {
        display: none;
    }
    .experience-carousel .carousel-control-prev {
        left: 0;
        top: calc(50% - 1vw);
        z-index: 200;
    }
    .experience-carousel .carousel-control-next {
        right: 0;
        top: calc(50% - 1vw);
        z-index: 200;
    }
    .experience-carousel .carousel-control-next-icon {
        background-image: url('../images/next-white.svg');
    }
    .cuvees {
        position: relative;
        margin-top: 0;
    }
    .cuvees .one-wine {
        padding: 0;
        text-align: center;
    }
    .cuvees .one-wine img {
        margin: 5vh auto;
    }
    .cuvee-block-1 .right {
        height: 30vh;
    }
    .cuvee {
        text-align: center;
        font-family: classico-urw, sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    .cuvee .cuvee-block-1 .right {
        min-height: auto;
    }
    .cuvee .animated-block {
        margin: 0;
    }
    .cuvee .presentation img {
        position: relative;
        top: 0;
    }
    .cuvee .block-notes {
        background-color: #0033A1;
        padding: 5vh 0;
    }
    .cuvee .block-notes h2 {
        color: white;
    }
    .cuvee .text-oeil, .cuvee .text-nez {
        margin-bottom: 5vh;
    }
    .cuvee .text-oeil, .cuvee .text-nez, .cuvee .text-bouche {
        display: block;
    }
    .cuvee .text-bouche {
        margin-bottom: 0;
    }
    .cuvee .contenu {
        text-align: center;
        font-family: classico-urw, sans-serif;
        font-weight: 400;
        font-style: normal;
        margin-bottom: 5vh;
    }
    .cuvee .presentation {
        margin: 5vh auto 0 auto;
        text-align: center;
    }
    .cuvee h1 {
        font-size: 7vw;
        font-family: 'Oswald', Tahoma, Geneva, Verdana, sans-serif;
        margin-bottom: 5vh;
        margin-top: 5vh;
    }
    .cuvee h2 {
        font-size: 1.5rem;
    }
    .text-cuvee-mobile {
        background-color: #0033A1;
        padding: 4vw 0;
        text-align: center;
    }
    .text-cuvee-mobile p {
        margin: 0;
    }
    .text-cuvee-mobile a, .text-cuvee-mobile h2 {
        color: white;
        font-family: 'Oswald', sans-serif !important;
        font-weight: 200;
    }
    .text-cuvee-mobile a:hover {
        text-decoration: none;
    }
    .contact {
        margin-bottom: 5vh;
    }
    .contact h1 {
        text-align: center;
        font-size: 7vw;
        margin: 5vh 0;
    }
    .contact input[type=submit] {
        font-size: 16px;
        height: auto;
    }
    .contact .adresse {
        margin-top: 7vh;
        text-align: center;
    }
    .contact .adresse p {
        font-size: 16px;
    }
    .legal-notices {
        text-align: center;
        margin-top: 29vh;
        font-size: 4vw;
    }
    .legal-notices h1 {
        font-size: 7vw;
        margin-bottom: 5vh;
    }
    .legal-notices p:nth-of-type(2n), .legal-notices p:nth-of-type(2n +1) {
        font-size: 4vw;
    }
    footer {
        padding: 4vw 0;
    }
    footer .social img {
        width: 7vw;
    }
    @media (max-width: 767px) {
        nav ul li {
            padding-bottom: 1vh;
            font-size: 5vw;
        }
        nav ul .little a {
            font-size: 4vw;
        }
        .home-block h1, .home-block h2, .home-block h3 {
            font-size: 7vw;
            text-align: center;
        }
        .home-block p, .home-block-2 .right .second ul {
            font-size: 4vw;
            margin-bottom: 0;
        }
        .home-block-2 .right .second ul {
            text-align: right;
        }
        .home-block-3 .third .cuvee-visu img {
            width: 25vw;
            /* width: 70%;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-45%, -40%);
            margin-bottom: -40%; */
        }
        .home-block-2 .carousel-control-prev {
            left: 0;
            top: calc(50% - 5vw);
            z-index: 200;
        }
        .home-block-2 .carousel-control-next {
            right: 0;
            top: calc(50% - 5vw);
            z-index: 200;
        }
        .home-block-3 .second a {
            font-size: 4vw;
            padding: 2vw;
        }
        .carousel-control-prev, .carousel-control-next, .carousel-control-prev-icon, .carousel-control-next-icon {
            width: 10vw;
            height: 10vw;
        }
        .house h1, .house h2, .house h3 {
            font-size: 7vw;
            text-align: center;
            margin-bottom: 5vh;
        }
        .house p {
            font-size: 4vw;
            text-align: center;
        }
        .experiences .listing .titrage h2 {
            font-size: 6.6vw;
        }
        .experiences .listing .titrage .little {
            font-size: 4vw;
        }
    }
    @media only screen and (orientation: landscape) and (max-width: 1024px) {
        .overlay {
            display: block;
            z-index: 666;
        }
        .overlay .phone {
            -webkit-animation: sizeIncrease .5s forwards ease, borderIncrease .5s .5s forwards ease, rotateLeft .7s 1s forwards ease;
            animation: sizeIncrease .5s forwards ease, borderIncrease .5s .5s forwards ease, rotateLeft .7s 1s forwards ease;
        }
        .overlay .phone i {
            -webkit-animation: fadeIn .5s .8s forwards ease;
            animation: fadeIn .5s .8s forwards ease;
        }
        .overlay .iconContainer p {
            -webkit-animation: fadeIn .5s 1.3s forwards ease;
            animation: fadeIn .5s 1.3s forwards ease;
        }
    }
    @-webkit-keyframes sizeIncrease {
        0% {
            width: 10px;
            height: 0;
        }
        50% {
            width: 10px;
            height: 100px;
        }
        100% {
            width: 160px;
            height: 100px;
        }
    }
    @keyframes sizeIncrease {
        0% {
            width: 10px;
            height: 0;
        }
        50% {
            width: 10px;
            height: 100px;
        }
        100% {
            width: 160px;
            height: 100px;
        }
    }
    @-webkit-keyframes borderIncrease {
        100% {
            border-width: 10px 20px;
        }
    }
    @keyframes borderIncrease {
        100% {
            border-width: 10px 20px;
        }
    }
    @-webkit-keyframes fadeIn {
        100% {
            color: white;
            opacity: 1;
        }
    }
    @keyframes fadeIn {
        100% {
            color: white;
            opacity: 1;
        }
    }
    @-webkit-keyframes rotateLeft {
        100% {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
        }
    }
    @keyframes rotateLeft {
        100% {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
        }
    }
}

/*@media (min-width: 1200px) {*/
/*    .experience .experience-block-1 {*/
/*        margin-top: -40vh;*/
/*    }*/
/*}*/
